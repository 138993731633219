.header-section {
  display: flex;
  align-items: center;
  height: 80px;
  background-color: #FEC20E;
  box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  justify-content: space-between;

  .profile-area {
    padding: 0 16px;
    display: flex;
    align-items: center;

    .email {
      font-size: 16px;
      font-weight: 500;
      margin: 0 16px;
    }

    svg {
      font-size: 24px;
      color: #000;
      cursor: pointer;
    }
  }

  img {
    padding: 0 16px;
    height: 60px;
  }
}