.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .select-area {
    border-radius: 16px;
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.15);
    height: 100%;
    width: 100%;
    max-width: 450px;
    margin: 0 16px;

    .tab-selector {
      margin: 24px 24px 0;
      border-radius: 25px;
      height: 36px;
      box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 7px;

      .tab {
        font-size: 15px;
        font-weight: 500;
        color: #303030;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 25px;
        cursor: pointer;
        transition: background-color 0.4s ease-in-out;
      }

      .tab-active {
        background-color: #FEC20E;
      }
    }

    .react-dropdown-select {
      border: 1.5px solid #e3dede;
      border-radius: 8px;
      height: 36px;
      transition: border .3s ease-in-out, color .3s ease-in-out;

      .react-dropdown-select-content {
        color: #303030;
        padding-left: 7px;
        font-size: 14px;
      }

      .react-dropdown-select-dropdown {
        border: unset;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

        .react-dropdown-select-item {
          padding: 10px 12px;
          font-size: 14px;

          &:hover {
            background: #fec20e1a;
          }
        }

        .react-dropdown-select-item-selected {
          background: #fec20e;
          color: #303030;
        }
      }

      &:hover {
        border-color: #fec20e;
      }

      &:focus {
        box-shadow: none;
      }

      &:focus-within {
        box-shadow: none;
      }
    }

    .submit-button {
      background-color: #FEC20E;
      color: #303030;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      border: none;
      border-radius: 26px;
      padding: 12px;
      cursor: pointer;
      transition: background-color 0.4s ease-in-out;

      &:hover {
        background-color: #f2b200;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      svg {
        color: white;
        animation: spin 1s linear infinite;
      }
    }

    .form {
      margin: 8px 36px 0;
      display: flex;
      flex-direction: column;

      .first-label {
        margin-top: unset !important;
      }

      .select-mode-button {
        margin-top: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #e8b418;
        font-weight: 500;

        span {
            font-size: 14px;
            margin-left: 4px;
        }
      }

      .csv-preview {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 12px 0;

        svg {
          position: absolute;
          transform: translateX(-36px);
          top: 0;
          cursor: pointer;
          color: #ff4f4f;
          font-size: 22px;
        }

        p {
          font-size: 12px;
          color: #303030;
          text-align: center;
          margin: -4px 0 4px 0;
        }

        img {
          height: 110px;
          width: 110px;
          margin: 0 auto;
        }
      }

      div[role="presentation"] {
        font-size: 14px;
        border: 1.5px dashed #e3dede;
        color: #757575;
        border-radius: 5px;
        padding: 20px;
        text-align: center;
        margin: 0 0 12px;
        cursor: pointer;
        transition: border .3s ease-in-out, color .3s ease-in-out;

        &:hover {
          border-color: #fec20e;
          color: #fec20e;
        }
      }


    }

    .view {
      margin: 16px 36px 0;
      display: flex;
      flex-direction: column;
      height: calc(100% - 120px);
      justify-content: center;
    }
  }

  @media only screen and (max-width: 768px) {
    .select-area {
      margin: 0 8px;

      .tab-selector {
        margin: 24px 12px 0;
      }

      .form {
        margin: 8px 16px 0;
      }

      .view {
        margin: 16px 12px 0;
      }
    }
  }
}