.Login {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-top: 80px;
  margin-bottom: 40px;
  color: #303030;

  label {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 12px;
  }

  input[type="text"], input[type="datetime-local"], input[type="password"] {
    height: 36px;
    color: #303030;
    transition: border .3s ease-in-out;
    border: 1.5px solid #e3dede;
    border-radius: 8px;
    padding: 0 12px;
  }

  input:hover {
    border-color: #FEC20E; /* adjust to match your app's color scheme */
  }

  input:focus {
    outline: none;
    border-color: #FEC20E; /* adjust to match your app's color scheme */
  }

  .content-area {
    height: calc(100vh - 130px);

    .dialog-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .dialog {
        border-radius: 16px;
        box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.15);
        height: 100%;
        width: 100%;
        max-width: 450px;
        max-height: 300px;
        margin: 0 16px;

        .form {
          margin: 8px 36px 0;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            margin: 12px 0;
          }

          .submit-button {
            margin-top: 24px;
            background-color: #FEC20E;
            color: #303030;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            border: none;
            border-radius: 26px;
            padding: 12px;
            cursor: pointer;
            transition: background-color 0.4s ease-in-out;

            &:hover {
              background-color: #f2b200;
            }

            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }

            svg {
              color: white;
              animation: spin 1s linear infinite;
            }
          }
        }
      }
    }
  }
}


