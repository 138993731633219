.view-page {
  height: 100%;

  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .event-title {
      font-size: 24px;
      font-weight: 600;
    }

    svg {
      font-size: 26px;
      color: #303030;
      cursor: pointer;
    }
  }

  .data-area {
    padding: 12px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    gap: 16px;
    height: calc(100% - 70px);

    .student-data {
      height: 100%;
      width: 100%;

      .ag-header-cell .ag-header-cell-menu-button {
        opacity: 1 !important;
      }

      .ag-header-cell-label {
        justify-content: center !important;
      }

      .attendance-cell {
        display: flex;
        justify-content: center;

        .percent {
          margin-left: 4px;
          font-size: 10px;
        }
      }
    }

    .session-data-section {
      overflow-y: scroll;

      .session-header {
        font-size: 18px;
        margin-bottom: 16px;
        text-align: center;
        font-weight: 600;
      }

      .session-details {
        padding: 0 8px;
        position: relative;

        svg {
          color: #ff4f4f;
          position: absolute;
          top: 6px;
          right: 12px;
          cursor: pointer;
          font-size: 18px;
        }

        .session-divider {
          height: 1px;
          background-color: #ccc;
          margin: 10px 32px;
        }

        .session-row {
          .session-label {
            font-weight: 400;
            color: #777;
            font-size: 10px;
          }

          .session-data {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}