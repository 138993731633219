.footer-section {
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 50px;

  p {
    text-align: center;
    padding: 0 16px;
    margin-bottom: 10px;
  }

  a {
    font-size: 14px;
    font-weight: bold;
    color: #FEC20E;
    cursor: pointer;
    text-decoration: none;
  }
}