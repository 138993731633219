.App {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-top: 80px;
  margin-bottom: 40px;
  color: #303030;

  label {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 12px;
  }

  input[type="text"], input[type="datetime-local"] {
    height: 36px;
    color: #303030;
    transition: border .3s ease-in-out;
    border: 1.5px solid #e3dede;
    border-radius: 8px;
    padding: 0 12px;
  }

  input:hover {
    border-color: #FEC20E; /* adjust to match your app's color scheme */
  }

  input:focus {
    outline: none;
    border-color: #FEC20E; /* adjust to match your app's color scheme */
  }
}